import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
//import { createClient } from "@supabase/supabase-js";
function Career() {
  //const supabaseUrl = "https://fqpddijztomvuirlxzng.supabase.co";
  //const supabaseKey =
  //  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZxcGRkaWp6dG9tdnVpcmx4em5nIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjIyMzAxOTcsImV4cCI6MjAzNzgwNjE5N30.pOxf-9XDuAFuziBDuQoLHeR4iwoOQvAis4uLxzFaabE"; //process.env.SUPABASE_KEY
  //const supabase = createClient(supabaseUrl, supabaseKey);

  //console.log(supabase, "supabase")
  function isValidEmail(email) {
    // Basic email validation regex
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const [Phonenumber, setPhonenumber] = useState("");
  const [Email, setEmail] = useState("");
  const [msg, setmsg] = useState("");
  const [UserName, setUserName] = useState("");
  const [msgsubject, setmsgsubject] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [0]);
  // async function submitCustmDataEmailNew() {
  //   debugger;

  //   // Validate required fields
  //   if (UserName === "" || Email === "" || msg === ""||msgsubject=== "") {
  //     alert("Please fill out all required fields.");
  //     return;
  //   }
  //   // Validate email format (basic check)
  //   else if (!isValidEmail(Email)) {
  //     alert("Please enter a valid email address.");
  //     return;
  //   } else {
  //     const { data, error } = await supabase
  //       .from("Comman_CareerForm")
  //       .insert({
  //         ClientId: "30223f5c-4364-4cb3-b1e8-91854923b2ba",
  //         Query: msg,
  //         Name: UserName,
  //         Email: Email,
  //          Mobile: "",
  //         Subject: msgsubject,
  //       })
  //       .select("ID");

  //     if (error != null) {
  //       alert(error.details);
  //       return;
  //     }
  //     if (data) {
  //       alert(
  //         "Thank you for reaching out to us; we will get back to you shortly. Lead id: " +
  //           data[0].ID
  //       );
  //       window.scrollTo(0, 0);
  //       window.location.reload();
  //     }
  //   }
  // }

  const submitCustmDataEmailNew = async () => {
    // Validate required fields
    if (UserName === "" || Email === "" || msg === "" || msgsubject === "") {
      alert("Please fill out all required fields.");
      return;
    }
    // Validate email format (basic check)
    else if (!isValidEmail(Email)) {
      alert("Please enter a valid email address.");
      return;
    } else {
      // const data = {
      //   Query: msg,

      //   Email: Email,
      //   Mobile: Phonenumber,
      //   Name: UserName,
      //   ClientId: "30223f5c-4364-4cb3-b1e8-91854923b2ba",
      //   Subject: msgsubject,
      // };
      const data = {
        name: UserName,
        email: Email,
        subject: msgsubject,
        query: msg,
        domain: "tanejacapital.com", // Set your domain here
      };

      // const headers = {
      //   "Content-Type": "application/json",
      //   clientid: "30223f5c-4364-4cb3-b1e8-91854923b2ba",
      //   "page-name": "Career_Form",
      //   //"SendToEmail": "Chandrakant111420@gmail.com",
      //   EmailSubject: "Career Form Submission",
      // };
      const headers = {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzI0OGY3ZjUxODY3NjM1OWNmNDc1N2UiLCJzZXNzaW9uIjoiNjcyNDhmN2Y1MTg2NzYzNTljZjQ3NTgwIiwibmFtZSI6IlRhbmVqYSBDYXBpdGFsIiwiZW1haWwiOiJrYW5pa2FAdGFuZWphY2FwaXRhbC5jb20iLCJ1c2VyVHlwZSI6InVzZXIiLCJ1c2VySWQiOiI2NzI0OGY3ZjUxODY3NjM1OWNmNDc1N2UiLCJpYXQiOjE3MzA0NDkyNzksImV4cCI6MTg4ODIzNzI3OX0.msY9uygvEHd-iz5I_vBnsC7GP2nUDWc-a3sCFV1_fljtZMybxY4t7iY5n-C_1TKWtthD-4P74wjH6wkINlnAVoqrbA_B9jgg7d9xdgywwZPc3ZJY5P2Qx2gMNPhFzFEhz0C5UojJCMIb7CXm3Mh5PjzgCVsckG3b4XxLbjVot66uL6Qb7dWVcjsVTUMUn_k9vqtHiZHZrPWk0ix8ZvFpjDDEfMMuMWypq9EJeY0TGuB4GR68Drj14CuseBwPXiLhuH0J8-QDrgYgoE8l7JFEZTHAEtB34WVzwyLJ3TZdPE8IdnDQxC2FdCDjbKwAi-TVIDTcn9FCm7OXu4e0rXV8Sg",
        "Accept-Language": "en",
        Accept: "application/json",
      };

      try {
        //"https://crmleads.erpthemes.com/save-result",
        const response = await fetch(
          "https://projects.erpthemes.com/api/dynamic/addRecordsDynamic?tempID=19&tempName=taneja_capital_career",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          }
        );
        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        const result = await response.json();
        // console.log("Response:", result);
        if (result && result.resp) {
          if (result.resp?.status) {
            alert(result.resp?.message);
            window.scrollTo(0, 0);
            window.location.reload();
          } else {
            alert(result.resp?.message);
          }
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    }
  };
  return (
    <>
      <div>
        <Header />{" "}
      </div>
      <div>
        <section
          className="custom-padding services"
          style={{ padding: "120px 0", background: "#fff" }}
        >
          <div className="container">
            {/* title-section */}
            <div className="main-heading" style={{ marginBottom: 20 }}>
              <h2 style={{ fontSize: 28 }}>Career</h2>
              <p
                style={{
                  textAlign: "left",
                  margin: "0 0 20px 0",
                  width: "100%",
                  color: "#323232",
                }}
                title="Top CA firm in delhi-ncr"
              >
                We always look for the dynamic people to join our team. If you
                think you have the passion to keep up with our mission then do
                not hesitate to leave your resume with us.
              </p>
            </div>
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12  ">
                {/* <form id="contactForm" method="post" action="#"> */}
                <div className="row">
                  <div className="col-sm-6">
                    {/* Name */}
                    <div className="form-group">
                      <label>
                        Name <span className="required">* </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Name"
                        id="name"
                        name="name"
                        className="form-control"
                        required=""
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* End col-sm-6 */}
                  <div className="col-sm-6">
                    {/* Email */}
                    <div className="form-group">
                      <label htmlFor="email">
                        Email <span className="required">* </span>
                      </label>
                      <input
                        type="email"
                        placeholder="Email"
                        id="email"
                        name="email"
                        className="form-control"
                        required=""
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* End col-sm-6 */}
                <div className="row">
                  <div className="col-sm-12">
                    {/* Email */}
                    <div className="form-group">
                      <label>
                        Subject <span className="required">* </span>
                      </label>
                      <input
                        type="text"
                        placeholder="Subject"
                        id="subject"
                        name="subject"
                        className="form-control"
                        required=""
                        onChange={(e) => {
                          setmsgsubject(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* End col-sm-12 */}
                {/* End col-sm-6 */}
                <div className="row">
                  <div className="col-sm-12">{/* Email */}</div>
                </div>
                {/* End col-sm-12 */}
                <div className="row">
                  <div className="col-sm-12">
                    {/* Comment */}
                    <div className="form-group">
                      <label>
                        Message <span className="required">* </span>
                      </label>
                      <textarea
                        placeholder="Message..."
                        id="message"
                        name="message"
                        className="form-control"
                        rows={3}
                        required=""
                        defaultValue={""}
                        onChange={(e) => {
                          setmsg(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* End col-sm-12 */}
                <div className="row">
                  <div className="col-sm-12">
                    <button
                      type="submit"
                      id="yes"
                      className="btn btn-primary"
                      onClick={submitCustmDataEmailNew}
                    >
                      Send Message{" "}
                    </button>
                    <img
                      id="loader"
                      alt=""
                      src="images/loader.gif"
                      className="loader"
                    />
                  </div>
                </div>
                {/* End col-sm-6 */}
                {/* </form> */}
              </div>
              <div className="clearfix" />
            </div>
          </div>
          {/* end container */}
        </section>
      </div>
      <div>
        <Footer />{" "}
      </div>
    </>
  );
}

export default Career;
